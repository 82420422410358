import { FaMountain } from "react-icons/fa";
import { defineField, defineType } from "sanity";
import allPageBuilderSections, {
  pageBuilderOptions
} from "../../common/allPageBuilderSections";

export default defineType({
  name: "homepage",
  title: "Homepage",
  icon: FaMountain,
  type: "document",
  fieldsets: [
    {
      name: "hero",
      title: "Hero",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "covidProtection",
      title: "Covid protection",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "fares",
      title: "Fares",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "offers",
      title: "Offers",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "ships",
      title: "Ships",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "destinations",
      title: "Destinations",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "newsMessage",
      title: "Top page news message",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  groups: [
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  fields: [
    {
      name: "heroImages",
      title: "Hero Images",
      type: "array",
      of: [{ type: "heroSlideContent" }, { type: "heroSlidePromoContent" }],
      options: { hotspot: true },
      validation: (Rule) => Rule.required(),
      fieldset: "hero"
    },
    {
      name: "enableUrgentMessageSlide",
      title: "Enable Urgent Message Slide",
      description:
        "Use only for real urgent message (if enabled the auto slide of the hero banners will be disabled)",
      type: "boolean",
      fieldset: "hero"
    },
    {
      name: "urgentMessageSlide",
      title: "Urgent Message Slide",
      description: "Use only for real urgent message",
      type: "multilingualBlockContent",
      fieldset: "hero"
    },
    {
      name: "covidProtectionContent",
      title: "Content",
      type: "multilingualBlockContent",
      fieldset: "covidProtection"
    },
    {
      type: "ctaButtons",
      name: "covidProtectionCtaButtons",
      title: "CTA Buttons",
      fieldset: "covidProtection",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    defineField({
      name: "sections",
      type: "array",
      title: "Page sections",
      description: "Add, edit, and reorder sections",
      of: allPageBuilderSections(),
      options: {
        ...pageBuilderOptions
      }
    }),
    {
      name: "newsMessage",
      title: "News message",
      type: "multilingualBlockContent",
      fieldset: "newsMessage"
    },
    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: "metadata",
      group: "seo"
    }
  ],
  preview: {
    prepare(value) {
      return {
        title: "Homepage"
      };
    }
  }
});

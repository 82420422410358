import { FaMugHot } from "react-icons/fa";
import { defineType } from "sanity";

export default defineType({
  title: "Tea with Silversea Registration",
  name: "teaWithSilverseaRegistration",
  icon: FaMugHot,
  type: "object",
  fields: [
    {
      name: "color",
      title: "Colour",
      type: "string",
      options: {
        list: [{ title: "Yellow", value: "#FFBB4D" }]
      }
    }
  ]
});

import { FaTag } from "react-icons/fa";
import { defineField, defineType } from "sanity";
import { multilingualArrayString } from "../base";
import { multilingualStringPreview } from "../base/multilingualArrayString";

export default defineType({
  name: "cruisesTag",
  title: "Cruises Tag",
  type: "document",
  icon: FaTag,
  preview: {
    select: {
      title: "title"
    },
    prepare({ title }) {
      return {
        title: multilingualStringPreview(title)
      };
    }
  },
  fields: [
    defineField({
      name: "title",
      title: "Title",
      type: multilingualArrayString.name,
      description: "Short title for the menu",
      validation: (Rule) => Rule.required()
    }),
    defineField({
      name: "cruiseCodes",
      title: "Cruise Codes",
      description: "list of cruise codes (one per row) without any comma",
      type: "text"
    })
  ]
});

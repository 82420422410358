import { MdOndemandVideo } from "react-icons/md";
import { defineType } from "sanity";

export default defineType({
  title: "Hero Video",
  name: "openingHeroVideo",
  type: "object",
  icon: MdOndemandVideo,
  preview: {
    select: {
      title: "title.en",
      media: "video.posterImage"
    }
  },
  fields: [
    {
      name: "video",
      title: "Video",
      type: "videoWithPosterImage"
    },
    {
      name: "title",
      title: "Title",
      type: "multilingualString"
    },
    {
      name: "content",
      title: "Content",
      type: "multilingualBlockContent",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      type: "ctaButtons",
      name: "ctaButtons",
      title: "CTA Buttons",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ]
});

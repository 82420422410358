import { RiCarouselView } from "react-icons/ri";
import { defineType } from "sanity";

export default defineType({
  title: "Pages Slider",
  name: "pageSlider",
  type: "object",
  icon: RiCarouselView,
  fields: [
    {
      name: "sectionLayoutConfig",
      type: "sectionLayoutConfig",
      title: "Section Layout",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      name: "heading",
      title: "Heading",
      type: "sectionTitle"
    },
    {
      name: "items",
      title: "Slides",
      type: "array",
      of: [
        {
          type: "reference",
          to: { type: "page" }
        }
      ]
    }
  ],
  preview: {
    select: {
      title: "heading.title.en",
      items: "items"
    },
    prepare({ title, items }) {
      return {
        title: title || "([Pages] Slider)",
        subtitle: `${items.length} items`
      };
    }
  }
});

import { AiFillDatabase } from "react-icons/ai";
import { formatThisAndThatList } from "../../common/shared";
import { defineType } from "sanity";
import { isPresent } from "../../utils/checks";
import { MultilingualStringValue } from "../base/multilingualString";

const serviceTitles = (services: { title: MultilingualStringValue }[]) => {
  const titles = (services || [])
    .map((x) => x.title && x.title.en)
    .filter(isPresent);

  return titles.length ? formatThisAndThatList(titles) : undefined;
};

export default defineType({
  title: "Accordion text",
  name: "accordionText",
  type: "object",
  icon: AiFillDatabase,
  fields: [
    {
      name: "sectionLayoutConfig",
      type: "sectionLayoutConfig",
      title: "Section Layout",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      name: "heading",
      title: "Section Heading",
      type: "sectionTitle",
      options: {
        collapsable: true
      }
    },
    {
      name: "items",
      title: "Items",
      type: "array",
      of: [{ type: "accordionTextItem" }]
    }
  ],
  preview: {
    select: {
      title: "heading.title.en",
      items: "items"
    },
    prepare(selection) {
      const { title, items } = selection;
      return {
        title: title || serviceTitles(items) || "(Accordion text)",
        subtitle: `Items: ${items.length}`
      };
    }
  }
});

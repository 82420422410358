import { FaWpforms } from "react-icons/fa";
import { defineType } from "sanity";
import { imageWithAlt } from "../objects";
import { multilingualStringPreview } from "../base/multilingualString";

export default defineType({
  name: "inlineLeadForm",
  title: "Inline Lead Form",
  type: "object",
  icon: FaWpforms,

  fieldsets: [
    {
      name: "api",
      title: "API",
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "fields",
      title: "Form Fields",
      options: { collapsible: true, collapsed: true }
    }
  ],

  fields: [
    {
      name: "sectionLayoutConfig",
      type: "sectionLayoutConfig",
      title: "Section Layout",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      name: "heading",
      title: "Heading",
      type: "sectionTitle"
    },
    {
      title: "background image",
      name: "backgroundImage",
      type: imageWithAlt.name
    },
    {
      title: "Thank You",
      name: "thankYouContent",
      type: "inlineLeadFormThankYou"
    },
    {
      title: "Ask Phone Number",
      name: "askPhoneNumber",
      type: "boolean",
      fieldset: "fields"
    },
    {
      name: "requestType",
      title: "Request Type",
      type: "string",
      fieldset: "api",
      options: {
        list: [
          { title: "Request a Quote", value: "RAQ" },
          { title: "Request a Call", value: "RAC" },
          { title: "Signup for Offers", value: "SFO" },
          { title: "Status Match", value: "SME" }
        ]
      },
      validation: (Rule) => Rule.required()
    },
    {
      name: "requestSource",
      title: "Request Source",
      type: "string",
      fieldset: "api",
      options: {
        list: [
          { title: "Request a Quote", value: "BKG" },
          { title: "Signup for Offers", value: "SFO" },
          { title: "Status Match", value: "WFSM" }
        ]
      },
      validation: (Rule) => Rule.required()
    }
  ],
  preview: {
    select: {
      heading: "heading"
    },
    prepare({ heading }) {
      return {
        title: multilingualStringPreview(heading.title) || "Inline Lead Form"
      };
    }
  }
});

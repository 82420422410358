import { RiPagesLine } from "react-icons/ri";
import { toSingleLanguage } from "../../common/localization";
import allPageBuilderSections, {
  pageBuilderOptions
} from "../../common/allPageBuilderSections";
import { defineField, defineType } from "sanity";

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();

const range = (start: number, stop: number, step: number) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => start + i * step
  ).reverse();

export const landingPageYears = range(
  currentMonth >= 10 ? currentYear + 1 : currentYear,
  2018,
  -1
);

export default defineType({
  name: "landingPage",
  title: "Landing Page",
  type: "document",
  icon: RiPagesLine,
  fieldsets: [{ name: "layout", title: "Layout" }],
  groups: [
    { name: "asCard", title: "Render as Card" },
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  orderings: [
    {
      title: "Title",
      name: "titleAsc",
      by: [{ field: "title.en", direction: "asc" }]
    }
  ],
  preview: {
    select: {
      title: "title",
      heroImage: "sections.0.background",
      cardImage: "card.image",
      slug: "metadata.routing.slug",
      parent: "metadata.routing.parent.metadata.routing.slug",
      archived: "archived",
      category: "category",
      year: "year"
    },
    prepare(value) {
      const slugs = [];
      if (value.parent) {
        slugs.push(toSingleLanguage(value.parent).current);
      }
      slugs.push(toSingleLanguage(value.slug).current);
      const category = [value.category || "un-categorized", value.year]
        .filter((i) => !!i)
        .join(" | ");

      return Object.assign({}, value, {
        title: toSingleLanguage(value.title),
        subtitle: `${
          value.archived ? "[Archived]" : ""
        } ${category} ~ /${slugs.join("/")}`,
        media: value.heroImage || value.cardImage
      });
    }
  },
  fields: [
    {
      name: "archived",
      title: "Archived",
      description:
        "Archive an old landing page and prevent it to be live on the website",
      type: "boolean"
    },
    {
      name: "layout",
      title: "Layout",
      type: "string",
      fieldset: "layout",
      options: {
        list: [
          { title: "Current (default)", value: "current" },
          { title: "New", value: "new" },
          { title: "Inbound Experiment", value: "inboundExperiment" }
        ]
      }
    },
    {
      name: "layoutOptions",
      title: "Layout Options",
      type: "newLayoutOptions",
      fieldset: "layout",
      hidden: ({ document }) => document?.layout != "new",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    defineField({
      name: "title",
      title: "Title",
      type: "multilingualString",
      validation: (Rule) => Rule.required(),
      options: {
        collapsible: true,
        collapsed: true
      }
    }),
    {
      name: "category",
      title: "Category",
      type: "string",
      options: {
        list: [
          { title: "Destination", value: "destination" },
          { title: "Ship", value: "ship" },
          { title: "Brand", value: "brand" },
          { title: "NWS", value: "nws" },
          { title: "Other", value: "other" },
          { title: "Not Used", value: "notused" }
        ]
      }
    },
    {
      name: "year",
      title: "Year",
      type: "number",
      options: {
        list: landingPageYears.map((y) => ({ title: y.toString(), value: y }))
      }
    },
    {
      name: "sections",
      type: "array",
      title: "Page sections",
      description: "Add, edit, and reorder sections",
      of: allPageBuilderSections(),
      options: {
        ...pageBuilderOptions
      }
    },
    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: "metadata",
      group: "seo"
    },
    {
      name: "card",
      title: "Render as Card",
      type: "asCard",
      group: "asCard"
    }
  ]
});

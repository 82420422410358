import { MdViewCarousel } from "react-icons/md";
import { defineType } from "sanity";

export default defineType({
  title: "Section Slider",
  name: "sectionSlider",
  type: "object",
  icon: MdViewCarousel,
  fieldsets: [
    {
      name: "cardButton",
      title: "Card Button",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  fields: [
    {
      name: "sectionLayoutConfig",
      type: "sectionLayoutConfig",
      title: "Section Layout",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      name: "heading",
      title: "Heading",
      type: "sectionTitle",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "items",
      title: "Cards",
      type: "array",
      of: [{ type: "sectionSliderCard" }]
    },
    {
      fieldset: "cardButton",
      name: "buttonVariant",
      type: "buttonType",
      description: 'if not provided, "Link" variant will be used'
    },
    {
      fieldset: "cardButton",
      name: "buttonLabel",
      type: "multilingualString",
      description: 'if not provided, "discover more" variant will be used'
    }
  ],
  preview: {
    select: {
      title: "heading.title.en",
      items: "items"
    },
    prepare({ title, items }) {
      return {
        title: title || "(Section Slider)",
        subtitle: `${items.length} slides`
      };
    }
  }
});

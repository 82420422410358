import { BsGrid3X2 } from "react-icons/bs";
import { defineType } from "sanity";

export default defineType({
  title: "Blog Mosaic",
  name: "blogMosaic",
  type: "object",
  icon: BsGrid3X2,
  fields: [
    {
      name: "content",
      title: "Content",
      type: "blogSection"
    }
  ],
  preview: {
    select: {
      title: "content.intro.title"
    }
  }
});

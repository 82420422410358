import { FaHeading } from "react-icons/fa";
import { defineType } from "sanity";

export default defineType({
  title: "Opening text",
  name: "openingText",
  icon: FaHeading,
  type: "object",
  fields: [
    {
      name: "sectionLayoutConfig",
      type: "sectionLayoutConfig",
      title: "Section Layout",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      name: "title",
      title: "Title",
      type: "multilingualString"
    },
    {
      name: "subtitle",
      title: "Subtitle",
      type: "multilingualString"
    },
    {
      name: "content",
      title: "Content",
      type: "multilingualBlockContent",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      type: "ctaButtons",
      name: "ctaButtons",
      title: "CTA Buttons",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  preview: {
    select: {
      title: "title.en"
    },
    prepare({ title }) {
      return {
        title: title || "([Opening] text)",
        subtitle: "Opening Text"
      };
    }
  }
});

import { BsCardText } from "react-icons/bs";
import { defineType } from "sanity";
import { contentsTitles } from "../../utils/preview";

export default defineType({
  title: "Location Specific Text sections",
  name: "locationSpecificTextBoxes",
  type: "object",
  icon: BsCardText,
  fields: [
    {
      name: "sectionLayoutConfig",
      type: "sectionLayoutConfig",
      title: "Section Layout",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      name: "heading",
      title: "Section Heading",
      type: "sectionTitle",
      options: {
        collapsable: true
      }
    },
    {
      name: "rows",
      title: "Rows",
      type: "array",
      of: [{ type: "locationSpecificTextBox" }]
    },
    {
      name: "boxed",
      title: "Is Boxed",
      type: "boolean"
    }
  ],
  preview: {
    select: {
      title: "heading.title.en",
      contents: "rows"
    },
    prepare(selection) {
      const { title, contents } = selection;
      return {
        title:
          title ||
          contentsTitles(contents) ||
          "(Location Specific Text sections)",
        subtitle: `Rows: ${contents?.length}`
      };
    }
  }
});

import { FaHeading } from "react-icons/fa";
import { defineType } from "sanity";

export default defineType({
  title: "Hero Slider",
  name: "openingHeroSlider",
  type: "object",
  icon: FaHeading,
  fields: [
    {
      name: "heroImages",
      title: "Hero Images",
      type: "array",
      of: [{ type: "heroSlideContent" }],
      options: { hotspot: true },
      validation: (Rule) => Rule.required()
    }
  ],
  preview: {
    select: {
      items: "heroImages"
    },
    prepare({ items }) {
      return {
        title: `${items?.length} items` || "([Opening] Hero Slider)",
        subtitle: "[Opening] Hero Slider"
      };
    }
  }
});

import { BsCardText } from "react-icons/bs";
import { defineType } from "sanity";

export default defineType({
  title: "Text section",
  name: "textBox",
  type: "object",
  icon: BsCardText,
  fields: [
    {
      name: "sectionLayoutConfig",
      type: "sectionLayoutConfig",
      title: "Section Layout",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      name: "heading",
      title: "Heading",
      type: "sectionTitle"
    },
    {
      name: "content",
      title: "Content",
      type: "multilingualBlockContent"
    },
    {
      name: "boxed",
      title: "Is Boxed",
      type: "boolean"
    }
  ],
  preview: {
    select: {
      title: "heading.title.en",
      subtitle: "heading.tagline.en"
    }
  }
});

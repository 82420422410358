import { FaQuestion } from "react-icons/fa";
import {
  defineArrayMember,
  defineField,
  defineType,
  TitledListValue
} from "sanity";
import { multilingualStringPreview } from "../base/multilingualArrayString";
import { orderRankField } from "@sanity/orderable-document-list";

const steps: TitledListValue<string>[] = [
  { value: "guests-and-fare", title: "Guests and Fare" },
  { value: "suite-category", title: "Suite Category" },
  { value: "guests-info", title: "Guests Info" },
  { value: "payment", title: "Payment" }
];

export default defineType({
  name: "dynamicFAQ",
  title: "Dynamic FAQ",
  type: "document",
  icon: FaQuestion,

  preview: {
    select: {
      title: "title",
      visibleInSteps: "visibleInSteps"
    },
    prepare: ({ title, visibleInSteps }) => {
      const subtitle = visibleInSteps
        ?.map((v: string) => steps.find((s) => s.value == v)?.title)
        .join(", ");
      return {
        title: multilingualStringPreview(title),
        subtitle: subtitle
      };
    }
  },
  fields: [
    defineField({
      name: "visibleInSteps",
      title: "Visible In Steps",
      type: "array",
      of: [defineArrayMember({ type: "string" })],
      options: {
        list: steps
      },
      validation: (Rule) => Rule.required()
    }),

    {
      name: "title",
      title: "Title",
      type: "multilingualArrayText",
      validation: (Rule) => Rule.required()
    },
    {
      name: "content",
      title: "Content",
      type: "multilingualArrayBlockContent"
    },
    orderRankField({ type: "dynamicFAQ", newItemPosition: "after" })
  ]
});

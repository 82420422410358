import { RiCarouselView } from "react-icons/ri";
import { defineType } from "sanity";

export default defineType({
  title: "Offers Selection Slider",
  name: "offersSelectionSlider",
  type: "object",
  icon: RiCarouselView,
  fields: [
    {
      name: "sectionLayoutConfig",
      type: "sectionLayoutConfig",
      title: "Section Layout",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      name: "heading",
      title: "Heading",
      type: "sectionTitle"
    },
    {
      name: "offers",
      title: "Offers",
      type: "array",
      of: [
        {
          type: "reference",
          to: [
            { type: "exclusiveOfferPerPromo" },
            { type: "cruiseGroupPerFare" }
          ]
        }
      ]
    }
  ],
  preview: {
    select: {
      title: "heading.title.en",
      offers: "offers"
    },
    prepare(selection) {
      const { title, offers } = selection;
      return {
        title: title || "([Offers Selection] Slider)",
        subtitle: `${offers?.length} offers`
      };
    }
  }
});

import { BiBookmarks } from "react-icons/bi";
import { defineType } from "sanity";

export default defineType({
  title: "Last seen products",
  name: "lastSeenProducts",
  type: "object",
  icon: BiBookmarks,
  fields: [
    {
      name: "sectionLayoutConfig",
      type: "sectionLayoutConfig",
      title: "Section Layout",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      name: "destination",
      type: "reference",
      to: [{ type: "destination" }]
    }
  ],
  preview: {
    select: {
      destination: "destination.name.en"
    },
    prepare(selection) {
      const { destination } = selection;
      return {
        title: destination
          ? `Last seen products in ${destination}`
          : "Last seen products"
      };
    }
  }
});

import { ArrayOptions } from "sanity";
import * as pageBuilderSections from "../schemas/page-builder";

type SectionName = keyof typeof pageBuilderSections;
export type PageBuilderSection = {
  _key: string;
  _type: SectionName;
  [k: string]: unknown;
};

export default () => {
  return Object.keys(pageBuilderSections)
    .sort((a: string, b: string) => {
      const titleA = pageBuilderSections[a as SectionName].title || "";
      const titleB = pageBuilderSections[b as SectionName].title || "";

      return titleA.localeCompare(titleB);
    })
    .map((type) => {
      return { type };
    });
};

const pageBuilderGroups = [
  {
    name: "hero",
    title: "Hero",
    of: [
      "openingHero",
      "openingHeroVideo",
      "openingHeroSlider",
      "openingText",
      "stickyNav"
    ]
  },
  {
    name: "slider",
    title: "Slider",
    of: [
      "cardSlider",
      "pageSlider",
      "collectionSlider",
      "cruiseSlider",

      "sectionSlider",
      "offersSelectionSlider"
    ]
  },
  {
    name: "mosaic",
    title: "Mosaic",
    of: [
      "pageMosaic",
      "collectionMosaic",
      "offersSelectionMosaic",
      "cardMosaic",
      "customOfferBox",
      "blogMosaic",
      "faresMosaic",
      "emotionalLauncher"
    ]
  },
  {
    name: "sections",
    title: "Sections",
    of: [
      "serviceRows",
      "videoSection",
      "fyc",
      "dividerWithCTA",
      "featuredContentRows",
      "offerDescriptionRow",
      "accordionText",
      "brochureLeadForm",
      "inlineCTABox",
      "subscribeToNewsletter",
      "milestones",
      "inlineLeadForm"
    ]
  },
  {
    name: "offers",
    title: "Offers",
    of: [
      "offersSelectionMosaic",
      "offersSelectionSlider",
      "customOfferBox",
      "offerDescriptionRow"
    ]
  },
  {
    name: "content",
    title: "Content",
    of: ["descriptionText", "textBox", "locationSpecificTextBoxes"]
  },
  {
    name: "lead",
    title: "Lead",
    of: ["brochureLeadForm", "subscribeToNewsletter", "inlineLeadForm"]
  },
  {
    name: "custom",
    title: "Custom",
    of: [
      "customComponent",
      "sharedSectionRef",
      "teaWithSilverseaRegistration",
      "lastSeenProducts",
      "expeditionTeamSection"
    ]
  }
];

export const pageBuilderOptions = {
  modal: {
    type: "dialog",
    width: "auto"
  },
  insertMenu: {
    filter: true,
    groups: pageBuilderGroups,
    views: [{ name: "list" }]
  }
} satisfies ArrayOptions<unknown>;

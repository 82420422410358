import { BsGrid3X2 } from "react-icons/bs";
import { defineType } from "sanity";

export default defineType({
  title: "Collection Mosaic",
  name: "collectionMosaic",
  type: "object",
  icon: BsGrid3X2,
  fields: [
    {
      name: "sectionLayoutConfig",
      type: "sectionLayoutConfig",
      title: "Section Layout",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      name: "heading",
      title: "Heading",
      type: "sectionTitle"
    },
    {
      name: "collection",
      title: "Collection",
      type: "string",
      options: {
        list: [
          { title: "Destinations", value: "destinations" },
          { title: "World Cruises", value: "world-cruises" },
          { title: "Grand Voyages", value: "grand-voyages" },
          { title: "Offers", value: "offers" },
          { title: "Ships", value: "ships" },
          { title: "Suite Categories", value: "suite-categories" },
          {
            title: "Dining Area Categories",
            value: "dining-area-categories"
          },
          {
            title: "Public Area Categories",
            value: "public-area-categories"
          }
        ]
      }
    },
    {
      name: "filterByType",
      title: "Filter by Type",
      type: "string",
      description: "leave empty to show all items",
      options: {
        list: [
          { title: "Ocean/Classic", value: "classic" },
          { title: "Expeditions", value: "expeditions" }
        ]
      },
      hidden: ({ parent }) =>
        !["destinations", "ships"].includes(parent?.collection)
    },
    {
      name: "mosaicVariant",
      description: "Layout variant for the mosaic",
      type: "string",
      validation: (Rule) => Rule.required(),
      options: {
        list: [
          { title: "3 Columns", value: "column3" },
          { title: "2 (first row) + 3 Columns", value: "column2+3" },
          { title: "2 Columns", value: "column2" }
        ]
      }
    }
  ],
  preview: {
    select: {
      title: "heading.title.en",
      collection: "collection"
    },
    prepare(selection) {
      const { title, collection } = selection;
      return {
        title: title || "([Collection] Mosaic)",
        subtitle: `Collection Mosaic: ${collection}`
      };
    }
  }
});

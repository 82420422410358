import { MdSettings } from "react-icons/md";
import allPageTypes from "../common/allPageTypes";
import destination from "./destinations";
import offer from "./offers";
import pages from "./pages";
import homepage from "./homepage";
import ship from "./ship";
import cruises from "./cruises";
import port from "./port";
import geoArea from "./geoArea";
import sharedSection from "./sharedSection";
import brochure from "./brochure";
import suiteCategoryTemplate from "./suiteCategoryTemplate";
import requestAQuote from "./requestAQuote";
import signupForOffers from "./signupForOffers";
import termsAndConditions from "./termsAndConditions";
import pressRelease from "./pressRelease";
import priceConfigurator from "./priceConfigurator";
import inclusions from "./inclusions";
import fare from "./fare";
import cancellationPolicyModal from "./cancellationPolicyModal";
import packages from "./packages";
import infographicPerCruiseGroup from "./infographicPerCruiseGroup";
import { StructureBuilder, StructureResolver } from "sanity/structure";
import ccpt from "./ccpt";
import expeditionTeam from "./expeditionTeam";
import microContentTypes from "./websiteMicroContent";
import redirects from "./redirects";
import mainMenu from "./mainMenu";

import * as allMicroContentTypes from "../schemas/micro-content";

const customPageTypes = allPageTypes().map((t) => t.type);

const hiddenDocTypes = [
  "site",
  "destination",
  "subDestination",
  "ship",
  "cruise",
  "cruisesLaunch",
  "port",
  "country",
  "region",
  "sharedSection",
  "suiteCategory",
  "diningAreaCategory",
  "publicAreaCategory",
  "cruiseData",
  "portData",
  "shorexData",
  "exclusiveOfferPerPromo",
  "cruiseGroupPerFare",
  "specialVoyage",
  "specialVoyageData",
  "shorex",
  "landProgramme",
  "landingPage",
  "cruisesSettings",
  "brochure",
  "brochureGrouping",
  "brochureContentGroup",
  "suiteCategoryTemplate",
  "diningAreaCategoryTemplate",
  "publicAreaCategoryTemplate",
  "pressRelease",
  "corporateIncentivePage",
  "cruiseWithConfidence",
  "inclusion",
  "cruiseGroupInclusions",
  "farePage",
  "cancellationPolicyModal",
  "package",
  "deckPlan",
  "deckPlanVersion",
  "deck",
  "shipVersion",
  "infographicPerCruiseGroup",
  "promoConfiguration",
  "themedCruise",
  "deckSuiteCategory",
  "termsAndConditionsSummary",
  "media.tag",
  "ccpt",
  "expeditionTeamMember",
  "termsAndConditionsByMarket",
  "highlightedCruiseGroup",
  "expeditionInclusionModal",
  "redirect",
  "topMenu",
  "mainMenu",
  "dynamicFAQ",
  "cruisesTag",
  ...Object.keys(allMicroContentTypes),
  ...customPageTypes
];

const divider = (S: StructureBuilder) => S.divider();

const resolver: StructureResolver = (S, context) =>
  S.list()
    .title("Content")
    .items(
      [
        homepage,
        pages,
        pressRelease,
        sharedSection,
        microContentTypes,
        priceConfigurator,

        divider,
        fare,
        offer,
        cancellationPolicyModal,
        infographicPerCruiseGroup,
        inclusions,
        packages,
        divider,
        requestAQuote,
        signupForOffers,
        suiteCategoryTemplate,
        termsAndConditions,
        divider,
        cruises,
        destination,
        ship,
        port,
        expeditionTeam,
        brochure,
        divider,
        geoArea,
        divider,
        ...S.documentTypeListItems().filter(
          (listItem) => !hiddenDocTypes.includes(listItem.getId() || "")
        ),
        mainMenu,
        S.documentListItem()
          .schemaType(`site`)
          .icon(MdSettings)
          .id(`site-silversea-com`)
          .title(`Site`),
        redirects,
        divider,
        ccpt
      ].map((f) => {
        if (f instanceof Function) return f(S, context);
        return f;
      })
    );

export default resolver;

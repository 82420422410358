import { useMemo } from "react";
import {
  createTheme,
  darken,
  lighten,
  ThemeProvider
} from "@mui/material/styles";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef
} from "material-react-table";
import { CruiseDiagnosticImporterLog } from "@silversea-ssc/dotcom-api-types-effect/CruiseDiagnosticImporterLog";
import { enhanceLogDescription } from "./CruiseDiagnosticImporterLogDetailsCell";
import CruiseDiagnosticImporterLogLevelBadge from "./CruiseDiagnosticImporterLogLevelBadge";

export type LogItem = {
  level: "WARN" | "ERROR" | "INFO" | "DEBUG";
  message: string;
  service: string;
  date: string;
  details: string;
  raw: Record<string, any>;
};

export type CruiseDiagnosticImporterLogTableProps = {
  logs: readonly CruiseDiagnosticImporterLog[] | undefined;
};

const CruiseDiagnosticImporterLogTable = ({
  logs
}: CruiseDiagnosticImporterLogTableProps) => {
  const theme = createTheme({
    palette: {
      mode: "dark"
    },
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "1.1rem"
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          thumb: {
            color: "#7897fe"
          }
        }
      }
    }
  });

  const columns = useMemo<MRT_ColumnDef<LogItem>[]>(
    () => [
      {
        accessorKey: "service",
        header: "Function",
        Cell: ({ cell }) =>
          cell.row.original.service.replace(/shop-api-importers-(.*)-/, ""),
        size: 200
      },
      {
        accessorKey: "message",
        header: "Message",
        size: 280
      },
      {
        accessorKey: "date",
        header: "Date",
        size: 120
      },
      {
        accessorKey: "level",
        header: "Log Level",
        Cell: ({ cell }) => (
          <CruiseDiagnosticImporterLogLevelBadge
            level={cell.row.original.level}
          />
        ),
        size: 140,
        enableColumnFilter: false
      },
      {
        accessorKey: "details",
        header: "Details",
        Cell: ({ cell }) => enhanceLogDescription(cell.row.original),
        enableColumnFilter: false
      }
    ],
    []
  );

  const data: LogItem[] = useMemo<LogItem[]>(
    () =>
      (logs ?? []).map((log) => ({
        level: log.level,
        message: log.message,
        service: log.service,
        date: new Date(log.timestamp).toLocaleString(),
        details: "",
        raw: log
      })),
    [logs]
  );

  const baseBackgroundColor = "#13141b";

  const table = useMaterialReactTable({
    columns,
    data,
    enableExpandAll: false,
    enableGlobalFilter: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnFilterModes: true,
    initialState: {
      density: "compact",
      grouping: ["service", "message"],
      pagination: { pageIndex: 0, pageSize: 50 },
      sorting: [{ id: "date", desc: true }]
    },
    muiTableBodyProps: {
      sx: () => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: darken(baseBackgroundColor, 0.1)
          },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          {
            backgroundColor: darken(baseBackgroundColor, 0.2)
          },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: lighten(baseBackgroundColor, 0.1)
          },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          {
            backgroundColor: darken(baseBackgroundColor, 0.2)
          }
      })
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: baseBackgroundColor,
      draggingBorderColor: theme.palette.secondary.main
    })
  });

  if (!logs) return;
  return (
    <ThemeProvider theme={theme}>
      <MaterialReactTable table={table} />
    </ThemeProvider>
  );
};

export default CruiseDiagnosticImporterLogTable;

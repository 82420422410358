import { FaWater } from "react-icons/fa";
import { MdSettings } from "react-icons/md";
import { RiPagesLine } from "react-icons/ri";
import { publishAll } from "../utils/publishAll";
import { StructureBuilder } from "sanity/structure";

export default (S: StructureBuilder) =>
  S.listItem()
    .title("Cruise")
    .icon(FaWater)
    .child(
      S.list()
        .title("Cruises")
        .items([
          S.listItem()
            .title("Cruises (all)")
            .schemaType("cruise")
            .child(
              S.documentTypeList("cruise").menuItems([
                ...(S.documentTypeList("cruise").getMenuItems() || []),
                S.menuItem()
                  .title("Publish all")
                  .action(() =>
                    publishAll("cruise", S.context.getClient, S.context.schema)
                  )
              ])
            ),
          S.listItem()
            .title("Cruises (visible)")
            .schemaType("cruise")
            .child(
              S.documentTypeList("cruise")
                .title("Cruises (visible)")
                .menuItems([
                  ...(S.documentTypeList("cruise").getMenuItems() || []),
                  S.menuItem()
                    .title("Publish all")
                    .action(() =>
                      publishAll(
                        "cruise",
                        S.context.getClient,
                        S.context.schema
                      )
                    )
                ])
                .filter(`_type == "cruise" && data->isVisible == true`)
            ),
          S.listItem()
            .title("Cruises (hidden)")
            .schemaType("cruise")
            .child(
              S.documentTypeList("cruise")
                .title("Cruises (hidden)")
                .menuItems([
                  ...(S.documentTypeList("cruise").getMenuItems() || []),
                  S.menuItem()
                    .title("Publish all")
                    .action(() =>
                      publishAll(
                        "cruise",
                        S.context.getClient,
                        S.context.schema
                      )
                    )
                ])
                .filter(
                  `_type == "cruise" && (!defined(data) || data->isVisible == false)`
                )
            ),
          S.divider(),
          S.listItem()
            .title("Special Voyages (all)")
            .schemaType("specialVoyage")
            .child(
              S.documentTypeList("specialVoyage").menuItems([
                ...(S.documentTypeList("specialVoyage").getMenuItems() || []),
                S.menuItem()
                  .title("Publish all")
                  .action(() =>
                    publishAll(
                      "specialVoyage",
                      S.context.getClient,
                      S.context.schema
                    )
                  )
              ])
            ),
          S.listItem()
            .title("Grand Voyages")
            .schemaType("specialVoyage")
            .child(
              S.documentTypeList("specialVoyage")
                .title("Grand Voyages")
                .menuItems([
                  ...(S.documentTypeList("specialVoyage").getMenuItems() || []),
                  S.menuItem()
                    .title("Publish all")
                    .action(() =>
                      publishAll(
                        "specialVoyage",
                        S.context.getClient,
                        S.context.schema
                      )
                    )
                ])
                .filter(
                  `_type == "specialVoyage" && data->comboType == "Grand"`
                )
            ),
          S.listItem()
            .title("World Cruises")
            .schemaType("specialVoyage")
            .child(
              S.documentTypeList("specialVoyage")
                .title("World Cruises")
                .menuItems([
                  ...(S.documentTypeList("specialVoyage").getMenuItems() || []),
                  S.menuItem()
                    .title("Publish all")
                    .action(() =>
                      publishAll(
                        "specialVoyage",
                        S.context.getClient,
                        S.context.schema
                      )
                    )
                ])
                .filter(
                  `_type == "specialVoyage" && data->comboType == "World"`
                )
            ),

          S.divider(),
          S.listItem()
            .title("Themed Cruises")
            .schemaType("themedCruise")
            .child(S.documentTypeList("themedCruise")),

          S.divider(),
          S.listItem()
            .title("Cruises Launches")
            .schemaType("cruisesLaunch")
            .child(S.documentTypeList("cruisesLaunch")),
          S.divider(),
          S.listItem()
            .title("Cruises Tag")
            .schemaType("cruisesTag")
            .child(S.documentTypeList("cruisesTag")),
          S.divider(),
          S.listItem()
            .title("Cruises Settings")
            .icon(MdSettings)
            .child(
              S.editor()
                .id("cruises-settings")
                .schemaType("cruisesSettings")
                .documentId("cruises-settings")
            )
        ])
    );

import { FaDivide } from "react-icons/fa";
import { defineType } from "sanity";

export default defineType({
  title: "Divider with CTA",
  name: "dividerWithCTA",
  type: "object",
  icon: FaDivide,
  fields: [
    {
      type: "ctaButtons",
      name: "ctaButtons",
      title: "CTA Buttons",
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  preview: {
    select: {
      title: "ctaButtons.0.label.en"
    }
  }
});

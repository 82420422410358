import { orderableDocumentListDeskItem } from "@sanity/orderable-document-list";
import { AiOutlineSafety } from "react-icons/ai";
import { FaShoppingCart, FaFileContract, FaQuestion } from "react-icons/fa";
import { StructureBuilder, StructureResolverContext } from "sanity/structure";

export default (S: StructureBuilder, context: StructureResolverContext) =>
  S.listItem()
    .title("Price Configurator Micro-Content")
    .icon(FaShoppingCart)
    .child(
      S.list()
        .title("Price Configurator")
        .items([
          orderableDocumentListDeskItem({
            type: "dynamicFAQ",
            S,
            context,
            title: "Dynamic FAQ",
            icon: FaQuestion
          }),
          S.listItem()
            .title("Cruise with confidence")
            .icon(AiOutlineSafety)
            .child(
              S.editor()
                .id("cruise-with-confidence-section")
                .schemaType("cruiseWithConfidence")
                .documentId("cruise-with-confidence-section")
            ),
          S.divider(),
          S.listItem()
            .title("Terms & Conditions")
            .icon(FaFileContract)
            .child(
              S.editor()
                .id("terms-and-conditions-summary")
                .schemaType("termsAndConditionsSummary")
                .documentId("terms-and-conditions-summary")
            )
        ])
    );

import { FaMoneyBill } from "react-icons/fa";
import {
  asCardBlockContent,
  asCardWithIcon,
  asPromoFeaturedCard,
  metadataWithoutParent
} from "../page-fragments";
import {
  filterableMultilingualBlockContent,
  multilingualBlockContent
} from "../base";
import allPageBuilderSections, {
  pageBuilderOptions
} from "../../common/allPageBuilderSections";
import filterableNumber from "../base/filterableNumber";
import { defineType } from "sanity";

export default defineType({
  name: "exclusiveOfferPerPromo",
  title: "Exclusive Offer Per Promo",
  type: "document",
  icon: FaMoneyBill,
  preview: {
    select: {
      title: "title.en",
      media: "heroImage"
    }
  },
  orderings: [
    {
      title: "Title",
      name: "titleAsc",
      by: [{ field: "title.en", direction: "asc" }]
    }
  ],
  fieldsets: [
    {
      name: "features",
      title: "Features",
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "visibility",
      title: "Visibility",
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "cards",
      title: "Cards",
      options: { collapsible: false }
    },
    {
      name: "icons",
      title: "Icons",
      options: { collapsible: true, collapsed: true }
    },
    {
      name: "stickybar",
      title: "Sticky Bar",
      options: { collapsible: true, collapsed: true }
    }
  ],
  groups: [
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  fields: [
    {
      name: "configuration",
      title: "Promo Configuration",
      type: "reference",
      to: [{ type: "promoConfiguration" }],
      validation: (Rule) => Rule.required()
    },
    {
      name: "showCountdown",
      title: "Show countdown",
      description:
        "Show countdown instead of expire date in the hero banner. This flag also enable the offer urgency box with countdown on offer cruises",
      type: "boolean"
    },
    {
      name: "countdownLabel",
      title: "Countdown label",
      type: "multilingualString",
      description: "Label for the countdown timer"
    },
    {
      name: "urgencyBoxContent",
      title: "Urgency Box content",
      type: "multilingualString"
    },
    {
      name: "heroImage",
      title: "Hero Image",
      type: "imageWithAlt",
      options: { hotspot: true },
      validation: (Rule) => Rule.required()
    },
    {
      name: "title",
      title: "Title",
      type: "multilingualString",
      validation: (Rule) => Rule.required()
    },
    {
      name: "extendedTitle",
      title: "Extended Title",
      description:
        "If present, will be shown in the offer page as title. You can use tokens.",
      type: "multilingualArrayBlockContent"
    },
    {
      name: "description",
      title: "Description",
      type: "multilingualBlockContent",
      validation: (Rule) => Rule.required()
    },
    {
      name: "fycFilterDescription",
      title: "FYC filter description",
      type: "multilingualText"
    },
    {
      name: "customCTALabel",
      title: "Custom CTA Label",
      description: "if empty, the default CTA label will be used",
      type: "multilingualString",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "secondaryCTA",
      title: "Secondary CTA",
      type: "pageCTA",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "tertiaryCTA",
      title: "Tertiary CTA",
      type: "pageCTA",
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "featureTitle",
      title: "Title",
      type: "multilingualString",
      fieldset: "features"
    },
    {
      name: "features",
      type: "array",
      of: [{ type: "exclusiveOfferFeature" }],
      fieldset: "features"
    },
    {
      name: "sections",
      type: "array",
      title: "Page sections",
      description: "Above the FYC component",
      of: allPageBuilderSections(),
      options: {
        ...pageBuilderOptions
      }
    },
    {
      name: "sectionsBottom",
      type: "array",
      title: "Page sections (bottom)",
      description: "Below the FYC component",
      of: allPageBuilderSections(),
      options: {
        ...pageBuilderOptions
      }
    },
    {
      name: "hideInHomepage",
      title: "Hide in Homepage",
      type: "boolean",
      fieldset: "visibility"
    },
    {
      name: "hideInCollections",
      title: "Hide in Collections (page builder offers mosaic/slider)",
      type: "boolean",
      fieldset: "visibility"
    },
    {
      name: "showInCruiseFareTab",
      title: "Show a badge in the cruise fare tab",
      type: "boolean",
      fieldset: "visibility"
    },
    {
      name: "showInCruiseCard",
      title: "Show a badge in the cruise card",
      type: "boolean",
      fieldset: "visibility"
    },
    {
      name: "showInCruisePageHero",
      title: "Show a badge in the cruise page hero",
      type: "boolean",
      fieldset: "visibility"
    },
    {
      name: "showPageHeroBanner",
      title: "Show a banner in the homepage hero",
      type: "boolean",
      fieldset: "visibility"
    },
    {
      fieldset: "icons",
      name: "iconVertical",
      title: "Icon vertical (SVG)",
      description: "usually black and white",
      type: "image",
      options: {
        accept: "image/svg+xml"
      }
    },
    {
      fieldset: "icons",
      name: "iconHorizontal",
      title: "Icon horizontal (SVG)",
      description: "usually orange color",
      type: "image",
      options: {
        accept: "image/svg+xml"
      }
    },
    {
      fieldset: "icons",
      name: "iconSmall",
      title: "Icon small (SVG)",
      description: "usually black and white and square",
      type: "image",
      options: {
        accept: "image/svg+xml"
      }
    },
    {
      name: "order",
      title: "Order",
      description:
        "in ascending order (1: first in the list, 100: last in the list)",
      type: "array",
      of: [
        {
          type: filterableNumber.name
        }
      ]
    },
    {
      fieldset: "cards",
      name: "cardWithIcon",
      title: "Render as Slider Card with Icon",
      type: asCardWithIcon.name
    },
    {
      fieldset: "cards",
      name: "card",
      title: "Render as Picture Card",
      type: asCardBlockContent.name
    },
    {
      fieldset: "cards",
      name: "farePageCard",
      title: "Render as Fare Page Card",
      type: asPromoFeaturedCard.name
    },
    {
      name: "heroBannerContent",
      title: "Hero Banner Content",
      type: multilingualBlockContent.name,
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    {
      name: "termsAndConditionsFootnote",
      title: "T&C Footnote",
      type: "array",
      of: [
        {
          type: filterableMultilingualBlockContent.name
        }
      ]
    },
    {
      name: "termsAndConditions",
      title: "Terms & Conditions",
      type: "array",
      of: [
        {
          type: filterableMultilingualBlockContent.name
        }
      ]
    },
    {
      name: "stickyEnabled",
      title: "Enable",
      type: "boolean",
      fieldset: "stickybar"
    },
    {
      name: "stickyTitle",
      title: "Title",
      type: "multilingualString",
      fieldset: "stickybar"
    },
    {
      name: "stickyContent",
      title: "Title",
      type: "multilingualText",
      fieldset: "stickybar"
    },
    {
      name: "stickyCTA",
      title: "CTA",
      type: "ctaButtons",
      validation: (Rule) => Rule.max(1),
      fieldset: "stickybar"
    },
    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: metadataWithoutParent.name,
      group: "seo"
    }
  ]
});

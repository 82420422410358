import { MdOndemandVideo } from "react-icons/md";
import { defineType } from "sanity";

export default defineType({
  title: "Video Section",
  name: "videoSection",
  type: "object",
  icon: MdOndemandVideo,
  fields: [
    {
      name: "sectionTitle",
      title: "Title Area",
      type: "sectionTitle"
    },
    {
      name: "video",
      title: "Video",
      type: "videoWithPosterImage"
    }
  ],
  preview: {
    select: {
      title: "sectionTitle.title.en",
      media: "video.posterImage"
    }
  }
});

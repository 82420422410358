import { FaFileAlt } from "react-icons/fa";
import { toSingleLanguage } from "../../common/localization";
import allPageBuilderSections, {
  pageBuilderOptions
} from "../../common/allPageBuilderSections";
import { defineField, defineType } from "sanity";

export default defineType({
  name: "page",
  title: "Page",
  type: "document",
  icon: FaFileAlt,
  orderings: [
    {
      title: "Title",
      name: "titleAsc",
      by: [{ field: "title.en", direction: "asc" }]
    }
  ],
  groups: [
    { name: "asCard", title: "Render as Card" },
    {
      name: "seo",
      title: "SEO, Routing & Tracking"
    }
  ],
  preview: {
    select: {
      title: "title",
      path: "metadata.routing.path.en",
      heroImage: "sections.0.background",
      cardImage: "card.image"
    },
    prepare: (value) => ({
      title: toSingleLanguage(value.title),
      subtitle: value.path,
      media: value.heroImage || value.cardImage
    })
  },
  fields: [
    {
      name: "category",
      title: "Category",
      type: "string",
      options: {
        list: [
          { title: "Page", value: "" },
          { title: "SEO", value: "seo" }
        ]
      }
    },
    {
      name: "title",
      title: "Title",
      type: "multilingualString",
      validation: (Rule) => Rule.required(),
      options: {
        collapsible: true,
        collapsed: true
      }
    },
    defineField({
      name: "sections",
      type: "array",
      title: "Page sections",
      description: "Add, edit, and reorder sections",
      of: allPageBuilderSections(),
      options: {
        ...pageBuilderOptions
      }
    }),
    {
      name: "metadata",
      title: "SEO, Routing & Tracking",
      type: "metadata",
      group: "seo"
    },
    {
      name: "card",
      title: "Render as Card",
      type: "asCard",
      group: "asCard"
    }
  ]
});

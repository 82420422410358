import { MdArtTrack } from "react-icons/md";
import { formatThisAndThatList } from "../../common/shared";
import { defineType } from "sanity";
import { isPresent } from "../../utils/checks";
import { MultilingualStringValue } from "../base/multilingualString";

const serviceTitles = (services: { title: MultilingualStringValue }[]) => {
  const titles = (services || [])
    .map((x) => x.title && x.title.en)
    .filter(isPresent);

  return titles.length ? formatThisAndThatList(titles) : undefined;
};

export default defineType({
  title: "Service Rows",
  name: "serviceRows",
  type: "object",
  icon: MdArtTrack,
  fields: [
    {
      name: "heading",
      title: "Section Heading",
      type: "sectionTitle",
      options: {
        collapsable: true
      }
    },
    {
      name: "anchor",
      title: "Anchor",
      type: "string",
      validation: (Rule) => Rule.regex(/[a-z-]+/)
    },
    {
      name: "services",
      title: "Services",
      type: "array",
      of: [{ type: "serviceRow" }]
    }
  ],
  preview: {
    select: {
      title: "heading.title.en",
      services: "services"
    },
    prepare(selection) {
      const { title, services } = selection;
      return {
        title: title || serviceTitles(services) || "(Service Rows)",
        subtitle: `Services: ${services.length}`
      };
    }
  }
});

import { defineType } from "sanity";

export default defineType({
  name: "packageCard",
  type: "object",
  title: "Package Card",
  fields: [
    {
      name: "title",
      type: "multilingualString",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      name: "subtitle",
      type: "multilingualBlockContent",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      name: "content",
      type: "multilingualBlockContent",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      name: "footer",
      type: "multilingualBlockContent",
      options: {
        collapsed: true,
        collapsable: true
      }
    },

    {
      type: "ctaButtons",
      name: "ctaButtons",
      title: "CTA Buttons",
      validation: (Rule) => Rule.max(1),
      options: {
        collapsible: true,
        collapsed: true
      }
    }
  ],
  preview: {
    select: {
      title: "title.en",
      media: "image"
    },
    prepare({ title, media }) {
      return {
        title,
        media
      };
    }
  }
});

import { QueryKey, useQuery } from "react-query";
import * as Schema from "effect/Schema";
import * as E from "fp-ts/Either";
import { TreeFormatter } from "effect/ParseResult";

type FetchArgs = {
  cruiseCode?: string;
  silverseaApiRootUrl: string;
  action: string;
};

export const fetchFun = async <T>(url: string): Promise<T> => {
  const response = await fetch(url);

  if (!response.ok) throw new Error("Network response was not ok");
  return await response.json();
};

const fetchDiagnostic = async <T>({
  cruiseCode,
  silverseaApiRootUrl,
  action
}: FetchArgs): Promise<T> => {
  const url = new URL(action, silverseaApiRootUrl);
  if (cruiseCode) url.searchParams.append("cruiseCode", cruiseCode);

  return fetchFun<T>(url.toString());
};

type Args<T> = {
  queryKey: QueryKey;
  enabled: boolean;
  action: string;
  silverseaApiRootUrl: string;
  cruiseCode?: string;
  type?: Schema.Schema<T>;
};

export const useDiagnosticApi = <T>(props: Args<T>) => {
  const { queryKey, enabled, action, cruiseCode, silverseaApiRootUrl, type } =
    props;

  return useQuery({
    queryKey: queryKey,
    enabled: enabled,
    retry: false,
    queryFn: () =>
      fetchDiagnostic<T>({
        cruiseCode,
        silverseaApiRootUrl,
        action
      }).then((data) => {
        if (!type) return data;

        const decoded = Schema.decodeEither(type)(data);

        if (E.isLeft(decoded))
          throw new Error(
            `Decode error in ${action}: ${TreeFormatter.formatError(decoded.left)}`
          );
        return decoded.right;
      })
  });
};

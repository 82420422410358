import { FaBuffer } from "react-icons/fa";
import { defineType } from "sanity";

export default defineType({
  title: "Shared Section",
  name: "sharedSectionRef",
  type: "object",
  icon: FaBuffer,
  fields: [
    {
      name: "section",
      title: "Section",
      type: "reference",
      to: [{ type: "sharedSection" }]
    }
  ],
  preview: {
    select: {
      title: "section.slug.current"
    },
    prepare(selection) {
      const { title } = selection;
      return {
        title: title ? `Section: ${title}` : "(Shared Section)"
      };
    }
  }
});

import { defineType } from "sanity";

export default defineType({
  name: "itineraryItem",
  title: "Itinerary Item",
  type: "object",
  preview: {
    select: {
      dayNumber: "dayNumber",
      port: "port.name.en"
    },
    prepare({ dayNumber, port }) {
      return {
        title: `Day ${dayNumber}: ${port}`
      };
    }
  },
  fields: [
    {
      title: "Day Number",
      name: "dayNumber",
      type: "number",
      readOnly: true
    },
    {
      name: "port",
      title: "Port",
      type: "reference",
      to: [{ type: "port" }],
      readOnly: true,
      weak: true
    },
    {
      title: "Date",
      description: "YYYY-MM-DD",
      name: "date",
      type: "string",
      readOnly: true
    },
    {
      title: "Arrival Time",
      description: "HH:mm",
      name: "arrivalTime",
      type: "string",
      readOnly: true
    },
    {
      title: "Departure Time",
      description: "HH:mm",
      name: "departureTime",
      type: "string",
      readOnly: true
    },
    {
      title: "Overnight?",
      name: "isOvernight",
      type: "boolean",
      readOnly: true
    },
    {
      title: "Confirmed Shore Excursions",
      description:
        "Excursions confirmed available on this day (confirmed 180 days before departure)",
      name: "confirmedShorexes",
      type: "array",
      of: [{ type: "confirmedShorex" }],
      readOnly: true
    }
  ]
});
